import React from 'react'
import { graphql } from 'gatsby'
import SVG from 'react-inlinesvg'

import Main from '@templates/Main'
import TextBox from '@atoms/TextBox'
import SectionGrayMiddle from '@molecules/SectionGrayMiddle'
import SectionWhite from '@molecules/SectionWhite'
import ContactForm from '@molecules/ContactForm'
import GridContact from '@molecules/GridContact'
import Testimonials from '@organisms/Testimonials'
import SignupCTA from '@organisms/SignupCTA'

import clIcon from '@images/calendly.svg'
import fbIcon from '@images/facebook.svg'
import ttIcon from '@images/twitter.svg'

const ContactPage = ({ data, transitionStatus }) => {
  const pageData = data.pageData

  return (
    <Main seo={pageData.seoMetaTags} transitionStatus={transitionStatus}>
      <SectionGrayMiddle hero>
        <TextBox title="Contact" center>
          <h2>Want to know more?</h2>
          <p>Don't hesitate to contact us.</p>
        </TextBox>
      </SectionGrayMiddle>
      <SectionWhite>
        <GridContact>
          <div>
            <TextBox>
              <h3>
                You can write us
                <br /> an email
              </h3>
              <p>
                <a href="mailto:contact@ideolo.co">contact@ideolo.co</a>
              </p>
            </TextBox>
            <br />
            <br />
            <br />
            <TextBox>
              <h3>Schedule a meeting</h3>
              <p>
                <a
                  href="https://calendly.com/ideolo/demo"
                  target="_blank"
                  rel="noreferrer"
                >
                  <SVG src={clIcon} /> Select a Date & Time
                </a>
              </p>
            </TextBox>
            <br />
            <br />
            <br />
            <TextBox>
              <h3>find us here</h3>
              <p>
                <a
                  href="https://www.facebook.com/profile.php?id=100091479953788"
                  target="_blank"
                  rel="noreferrer"
                >
                  <SVG src={fbIcon} /> facebook
                </a>
              </p>
              <p>
                <a
                  href="https://twitter.com/GetIdeolo"
                  target="_blank"
                  rel="noreferrer"
                >
                  <SVG src={ttIcon} /> twitter
                </a>
              </p>
            </TextBox>
          </div>
          <div>
            <TextBox>
              <h3>or use this form</h3>
            </TextBox>
            <ContactForm
              id="contact"
              fields={[
                {
                  id: 'user_name',
                  name: 'user_name',
                  label: 'Name',
                  type: 'text',
                  kind: 'text',
                },
                {
                  id: 'user_email',
                  name: 'user_email',
                  label: 'Email',
                  type: 'email',
                  kind: 'text',
                },
                {
                  id: 'user_msg',
                  name: 'user_msg',
                  label: 'Message',
                  type: 'textarea',
                  kind: 'text',
                },
              ]}
            />
          </div>
        </GridContact>
      </SectionWhite>
      <Testimonials />
      <SignupCTA />
    </Main>
  )
}

export const query = graphql`
  query ContactPageQuery {
    pageData: datoCmsContactPage {
      seoMetaTags {
        tags
      }
    }
  }
`

export default ContactPage

import styled from 'styled-components'

export const Wrapper = styled.div`
  display: grid;
  grid-template-columns: repeat(1, 1fr);
  grid-column-gap: 30px;
  margin: 0 auto;
  width: 100%;
  max-width: 1123px;

  ${({ theme }) => theme.media.s} {
    display: grid;
    grid-template-columns: 4fr 8fr;
  }
`

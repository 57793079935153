import React from 'react'
import PropTypes from 'prop-types'

import { Wrapper, StyledTextarea, StyledInput, Label } from './styles.js'

const Input = ({ name, label, type, required, onChangeEvent }) => {
  const handleChange = e => {
    onChangeEvent && onChangeEvent(e)
  }

  return (
    <Wrapper>
      <Label htmlFor={name}>{label}</Label>
      {type === 'textarea' ? (
        <StyledTextarea
          name={name}
          type={type}
          placeholder={label}
          required={required}
          onChange={e => handleChange(e)}
        />
      ) : (
        <StyledInput
          name={name}
          type={type}
          placeholder={label}
          required={required}
          onChange={e => handleChange(e)}
        />
      )}
    </Wrapper>
  )
}

Input.propTypes = {
  name: PropTypes.string,
  label: PropTypes.string,
  type: PropTypes.string,
  required: PropTypes.bool,
  onChangeEvent: PropTypes.func,
}

Input.defaultProps = {
  name: '',
  label: '',
  type: 'text',
  required: true,
  onChangeEvent: () => {},
}

export default Input

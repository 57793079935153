import React from 'react'
import PropTypes from 'prop-types'

import { Wrapper } from './styles.js'

const GridContact = ({ children }) => {
  return <Wrapper>{children}</Wrapper>
}

GridContact.propTypes = {
  children: PropTypes.oneOfType([
    PropTypes.node,
    PropTypes.arrayOf(PropTypes.node),
  ]),
}

GridContact.defaultProps = {
  children: null,
}

export default GridContact

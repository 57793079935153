import styled, { css } from 'styled-components'

export const Wrapper = styled.div`
  position: relative;
  width: 100%;

  form {
    margin: 0;
    width: 100%;
  }

  ${({ theme }) => theme.media.l} {
  }
`

export const Response = styled.div`
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  margin: 0;
  font-size: 18px;
  text-align: center;
  background: #020305;
  ${({ active }) =>
    active
      ? `opacity: 1;`
      : css`
          opacity: 0;
          pointer-events: none;
        `}
`

export const Success = styled.div`
  h4 {
    color: green;
  }
`

export const Error = styled.div`
  h4 {
    color: red;
  }
`

export const Loading = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 100%;
  background: #020305;
  transition: 1s ease;

  h4,
  p {
    transition: all 1s 0s ease;
  }

  p {
    font-weight: 600;
  }

  ${({ active }) =>
    active ? `filter: blur(0); opacity: 1;` : 'filter: blur(5px); opacity: 0;'}
`

import React, { useState } from 'react'
import PropTypes from 'prop-types'
import * as emailjs from 'emailjs-com'

import Button from '@atoms/Button'
import Input from '@atoms/Input'

import { Wrapper, Response, Success, Error, Loading } from './styles.js'

const ContactForm = ({ id, fields }) => {
  const [formData, setFormData] = useState({
    user_name: '',
    user_email: '',
    message: '',
  })
  const [formSent, setFormSent] = useState(false)
  const [showResponse, setShowResponse] = useState(false)
  const [formSendSucces, setFormSendSucces] = useState(false)
  const [formSendError, setFormSendError] = useState(false)

  const handleUpdateField = e => {
    setFormData({
      ...formData,
      [e.target.name]: e.target.value,
    })
  }

  const handleSubmit = e => {
    e.preventDefault()
    setFormSent(true)
    setShowResponse(true)

    // const setWindowDataLayer = () => {
    //   window.dataLayer = window.dataLayer || []
    //   window.dataLayer.push({
    //     event: 'form_submitted',
    //     form_name: 'contact',
    //   })
    // }

    // typeof window !== 'undefined' && setWindowDataLayer()

    // emailjs
    //   .sendForm(
    //     'gmail-x8i3hxd',
    //     'home',
    //     '#contactForm',
    //     'user_Tm9Tz4MVw8WxGsPaxlOpn'
    //   )
    //   .then(
    //     response => {
    //       setFormSendSucces(true)
    //     },
    //     err => {
    //       setFormSendError(true)
    //     }
    //   )
  }

  return (
    <Wrapper id={id} sent={!formSent}>
      <form id="contactForm" onSubmit={e => handleSubmit(e)}>
        {fields.map(field =>
          field.kind === 'textarea' ? (
            <Input
              required
              textarea
              key={field.id}
              name={field.name}
              label={field.label}
              value={formData.user_name}
              onChangeEvent={e => handleUpdateField(e)}
            />
          ) : (
            <Input
              required
              key={field.id}
              type={field.type}
              name={field.name}
              label={field.label}
              value={formData.user_name}
              onChangeEvent={e => handleUpdateField(e)}
            />
          )
        )}
        <br />
        <br />
        <Button size="L" color="primary">
          Send
        </Button>
      </form>
      <Response active={showResponse}>
        {formSendSucces && <Success>Success</Success>}
        {formSendError && <Error>Error</Error>}
        <Loading active={!formSendSucces && !formSendError}>
          <p>Trwa przesyłanie danych kontaktowych</p>
        </Loading>
      </Response>
    </Wrapper>
  )
}

ContactForm.propTypes = {
  id: PropTypes.string.isRequired,
  header: PropTypes.string.isRequired,
  fields: PropTypes.arrayOf(PropTypes.object),
  response: PropTypes.object,
}

ContactForm.defaultProps = {
  id: 'contact',
  header: 'Contact Form',
  fields: [],
  response: {},
}

export default ContactForm

import styled from 'styled-components'

export const Wrapper = styled.div`
  position: relative;
  width: 100%;
  padding: 15px 0 0;
`

export const StyledTextarea = styled.textarea.attrs(
  ({ name, type, value, required, onChange }) => ({
    id: name,
    name: name,
    type: type,
    value: value,
    onChange: onChange,
    required: required,
  })
)`
  position: relative;
  outline: none;
  border: 2px solid var(--brand-500);
  border-radius: 4px;
  width: 100%;
  min-height: 300px;
  padding: 10px;
  background: transparent;

  &::placeholder {
    color: transparent;
  }

  &:focus + label,
  &:not(:placeholder-shown) + label {
    transform: translate3d(0, -100%, 0) scale(0.7);
  }

  &:valid:focus {
    border-color: black;
  }

  &:invalid:focus,
  &:invalid:visited {
    border-color: red;
  }
`

export const StyledInput = styled.input.attrs(
  ({ name, type, value, placeholder, required, onChange }) => ({
    id: name,
    name: name,
    type: type,
    value: value,
    placeholder: placeholder,
    onChange: onChange,
    required: required,
  })
)`
  position: relative;
  outline: none;
  border: 2px solid var(--brand-500);
  border-radius: 4px;
  padding: 10px;
  width: 100%;
  max-width: 400px;
  background: transparent;

  &::placeholder {
    color: transparent;
  }

  &:focus + label,
  &:not(:placeholder-shown) + label {
    transform: translate3d(0, -100%, 0) scale(0.7);
  }

  &:invalid:focus,
  &:invalid:visited {
    border-color: red;
  }
`

export const Label = styled.label.attrs(({ htmlFor }) => ({
  htmlFor: htmlFor,
}))`
  display: block;
  margin: 0 0 5px;
  width: 100%;
  font-weight: 600;
`
